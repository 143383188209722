
import React, { useState, useEffect, useRef } from "react";

import placeholder from './assets/test.jpg';
import Nav from "./nav";



export default function Home() { 

    return (
        <div className="App">

            <Nav/>
            
       
          <div>
         
         
    


          <div data-uk-grid className="collections uk-position-bottom">

      <div className="uk-width-1-1 uk-text-center">
      <h1 >Everyday Artefacts</h1>
      </div>

              <div className="collection uk-width-1-3 uk-padding-remove">
              <h4>Small Works</h4>
<p>Drawings and Paintings</p>
<div className="uk-cover-container uk-height-medium">
    <img src={placeholder} />
</div>

              </div>

              <div className="collection uk-width-1-3 uk-padding-remove">
              <h4>Colour Fields</h4>
<p>Abstract Colour Field</p>
<div className=" uk-cover-container uk-height-medium">
    <img src={placeholder} />
</div>

              </div>

              <div className=" uk-flex-stretch collection uk-width-1-3 uk-padding-remove">
              <h4>Collected Obects</h4>
<p></p>
<div className="uk-cover-container uk-height-medium collection-image-thumb">
    <img src={placeholder} />
</div>
              </div>


          </div>
          </div>

          </div>
        
      );

}