
import React, { useState, useEffect, useRef } from 'react';
import {

  Routes,
  Route,
  Switch,
  Link,
  useLocation
} from "react-router-dom";

import Home from './Home';
import NoMatch from './NoMatch'
import Collection from './Collection';


import logo from './atelier_thlema.png';
import './App.scss';


const App = () => {
  const location = useLocation();

  return (
    
   <Routes location={location} key={location.pathname}>
   
    <Route path="/" element={<Home />} />

    <Route path="*" element={<NoMatch />} />
  </Routes>
  )
 
}

export default App;
