
import React, { Component } from 'react';
import logo from './atelier_thlema.png';

class Nav extends Component {

    render() {
        
        return (
            
<div className="uk-position-top">
<nav className="uk-navbar-container uk-navbar-transparent">
    <div className="uk-container">
        <div uk-navbar="" className="uk-navbar">

            <div className="uk-navbar-left">

                <ul className="uk-navbar-nav">
                    <li><a href="#">Manifesto</a></li>
                    <li>
                        <a href="#" role="button" aria-haspopup="true">Collections</a>
                        <div className="uk-navbar-dropdown uk-drop" >
                            <ul className="uk-nav uk-navbar-dropdown-nav">
                                <li className="uk-active"><a href="#">Active</a></li>
                                <li><a href="#">Item</a></li>
                                <li><a href="#">Item</a></li>
                            </ul>
                        </div>
                    </li>
                    <li><a href="#">Item</a></li>
                </ul>
                <div class="uk-navbar-center">
                <img className="App-logo" src={logo}/>

                </div>
  

            </div>

        </div>
    </div>
</nav>

      
    </div>
        )}

}
export default Nav;